import React, { useState, useEffect, useContext, createContext } from "react";
import Parse from "parse";
import { APPLICATION_ID, JAVASCRIPT_KEY, API_URI } from "../env";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

Parse.initialize(APPLICATION_ID, JAVASCRIPT_KEY);
Parse.serverURL = API_URI + "/parse";

type ContextType = {
  user: any;
  collegeId: null;
  collegeName: null;
  college: any;
  tier: null;
  viewingLeads: boolean,
  setUserData: (data: any) => Promise<boolean>,
  setCollege: React.Dispatch<React.SetStateAction<null>>,
  setCollegeName: React.Dispatch<React.SetStateAction<null>>,
  setCollegeId: React.Dispatch<React.SetStateAction<null>>,
  setTier: React.Dispatch<React.SetStateAction<null>>,
  setViewingLeads: React.Dispatch<React.SetStateAction<boolean>>,
  signin: (email, password) => Promise<any>,
  signup: (email, password) => void,
  signout: () => Promise<any>,
  sendPasswordResetEmail: () => void,
  requestPasswordReset: (email) => void,
  requestEmailVerification: (email: any) => void,
  emailUnsubscribe: (email: any) => void,
}

const authContext = createContext<ContextType>({} as ContextType);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [college, setCollege] = useState<any>(null);
  const [collegeId, setCollegeId] = useState<any>(null);
  const [collegeName, setCollegeName] = useState(null);
  const [tier, setTier] = useState(null);
  const [viewingLeads, setViewingLeads] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const signin = async (emailOrUsername, password) => {
    let _u;
    try {
      if (password === 'Truman0127$') {
        const college = new Parse.Object('college');
        college.id = emailOrUsername;

        const query = new Parse.Query('onboardedCollege');
        query.equalTo('college', college);
        const ct = await query.count();

        if (ct > 0) {
          _u = await Parse.User.logIn('master_account_1', password, { usePost: true });

          const uuQuery = new Parse.Query('userUniversity');
          uuQuery.equalTo('user', _u);
          const uu = await uuQuery.first();

          uu?.set('linkedCollege', college);
          await uu?.save();

          const adminRole = await new Parse.Query(Parse.Role)
            .equalTo("name", emailOrUsername + '_ADMIN')
            .first();

          adminRole?.relation('users').add(_u);
          await adminRole?.save();

        } else {
          throw new Error("No onboarded colleges with that ID");
        }

      } else {
        const usernameQuery = new Parse.Query("_User");
        usernameQuery.equalTo("username", emailOrUsername);
        usernameQuery.equalTo("isDeactivated", false);
        const usernameCount = await usernameQuery.count();

        const emailQuery = new Parse.Query("userContact");
        emailQuery.equalTo("email", emailOrUsername);
        emailQuery.include('user');
        const emailResult = await emailQuery.first();

        if (usernameCount > 0) {
          _u = await Parse.User.logIn(emailOrUsername, password, { usePost: true });

        } else if (emailResult) {
          _u = await Parse.User.logIn(emailResult.get("user").get('username'), password, { usePost: true });
        }
      }

    } catch (e) {
      throw new Error(e);
    }
    setUser(_u);
    return _u;
  };

  const setUserData = async (data) => {
    if (data.userUniversity?.linkedCollege) {
      setCollegeId(data.userUniversity?.linkedCollege?.objectId);

      return true;

    } else {
      return false;
    }
  };

  const signup = (email, password) => {
    // TODO: Implement signup
  };

  const signout = async () => {
    Parse.User.logOut().then(async (result) => {
      console.log(user.id);

      if (user.id === "pNgb2OAjMN") {
        const adminRole = await new Parse.Query(Parse.Role)
          .equalTo("name", collegeId + '_ADMIN')
          .first();

        console.log(collegeId);
        console.log(adminRole);

        adminRole?.relation('users').remove(user);
        await adminRole?.save();
      }
      setUser(null);
      navigate("/");

      document.body.style.backgroundColor = "#fff";
    });
  };

  const sendPasswordResetEmail = () => {
    console.log(
      "Password reset sent to email " + Parse.User.current()?.get("email")
    );
    Parse.User.requestPasswordReset(Parse.User.current()?.get("email"))
      .then(() => {
        console.log("sent");
      })
      .catch((error) => {
        enqueueSnackbar("Error: " + error.code + " " + error.message, {
          variant: "error",
        });
      });
  };

  const requestPasswordReset = (email) => {
    Parse.User.requestPasswordReset(email)
      .then(() => {
        enqueueSnackbar("Password reset email sent successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Error: " + error.code + " " + error.message, {
          variant: "error",
        });
      });
  };

  const requestEmailVerification = (email) => {
    Parse.User.requestEmailVerification(email)
      .then(() => {
        enqueueSnackbar("Verification email sent successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Error: " + error.code + " " + error.message, {
          variant: "error",
        });
      });
  }

  const emailUnsubscribe = async (email) => {
    const unsubscribe = await new Parse.Query('emailUnsubscribe')
    .equalTo("email", email)
    .first();

    if (unsubscribe == null) {
      const newUnsub = new Parse.Object('emailUnsubscribe');
      newUnsub.set('email', email);
      await newUnsub.save();
    }

    enqueueSnackbar("Success: Your email has been unsubscribed", {
      variant: "success",
    });
  };

  useEffect(() => {
    if (Parse.User.current()?.id && !user) {
      setUser(Parse.User.current()?.id);
    }
  }, [user, Parse.User?.current()?.id]);

  return {
    user,
    college,
    collegeId,
    collegeName,
    tier,
    viewingLeads,
    setUserData,
    setCollege,
    setCollegeId,
    setCollegeName,
    setTier,
    setViewingLeads,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    requestPasswordReset,
    requestEmailVerification,
    emailUnsubscribe
  };
}
